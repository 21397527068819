import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { supabase } from "../supabase/client";
import { useAuth } from "../context/AuthProvider";
import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import CollectionsModal from "../Components/CollectionsModal";

export function CampsiteView() {
  const { id } = useParams();
  const { user } = useAuth();
  const [campsiteData, setCampsiteData] = useState({
    latitude: 0,
    longitude: 0,
    checkins: [],
  });
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);
  const navigate = useNavigate();

  // Parse the numeric ID from the parameter
  const parseId = (idString) => {
    const match = idString.match(/^\d+/);
    return match ? parseInt(match[0]) : null;
  };

  const checkFavoriteStatus = async (numericId) => {
    // Skip the request if user is not logged in
    if (!user?.id) {
      setIsFavorited(false);
      return;
    }

    const { data, error } = await supabase
      .from("favorites")
      .select("id")
      .eq("campsite_id", numericId)
      .eq("user_id", user.id)
      .single();

    if (error && error.code !== "PGRST116") {
      // PGRST116 is the error code for no rows returned
      console.error("Error checking favorite status:", error);
      return;
    }

    setIsFavorited(!!data);
  };

  const toggleFavorite = async () => {
    if (!user?.id) {
      setShowLoginPrompt(true);
      return;
    }

    setFavoriteLoading(true);
    const numericId = parseId(id);

    try {
      if (isFavorited) {
        // Remove from favorites
        const { error } = await supabase
          .from("favorites")
          .delete()
          .eq("campsite_id", numericId)
          .eq("user_id", user.id);

        if (error) throw error;
        setIsFavorited(false);
      } else {
        // Add to favorites
        const { error } = await supabase.from("favorites").insert([
          {
            campsite_id: numericId,
            user_id: user.id,
          },
        ]);

        if (error) throw error;
        setIsFavorited(true);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleAddToCollection = () => {
    if (!user?.id) {
      setShowLoginPrompt(true);
      return;
    }
    setShowCollectionsModal(true);
  };

  async function fetchCampsiteData() {
    const numericId = parseId(id);

    if (!numericId) {
      setNotFound(true);
      setLoading(false);
      return;
    }

    const { data, error } = await supabase
      .from("campsites")
      .select(
        `*,
            checkins (
                *,
                profiles (
                    username
                )
            ),
            profiles (
                username
            )
        `
      )
      .eq("id", numericId);

    if (error) {
      console.error("Error fetching campsites:", error);
      setNotFound(true);
    } else if (data && data.length > 0) {
      // couldn't figure out how to sort the checkins in the query so i'm doing it here
      data[0].checkins.sort(
        (a, b) => new Date(b.check_in_date) - new Date(a.check_in_date)
      );
      setCampsiteData(data[0]);
      await checkFavoriteStatus(numericId);
    } else {
      setNotFound(true);
    }
    setLoading(false);
  }

  function costFormatter(val) {
    if (val === 0) {
      if (campsiteData.donation_based) {
        return "Donation";
      } else {
        return "Free";
      }
    } else if (val) {
      if (campsiteData.cost_unit) {
        return "$" + val + "/" + campsiteData.cost_unit;
      } else {
        return "$" + val;
      }
    }
  }

  function unknownFormatter(val) {
    if (val) {
      return "yes";
    } else if (val === null || val === undefined) {
      return "unknown";
    } else {
      return "no";
    }
  }

  function unknownFormatterOptions(val) {
    if (val === null || val === undefined) {
      return "unknown";
    } else {
      return val;
    }
  }

  function websiteFormatter(val) {
    if (val) {
      return (
        <a
          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          href={val}
        >
          URL
        </a>
      );
    } else {
      return "n/a";
    }
  }

  function phoneFormatter(val) {
    if (val) {
      return (
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          href={"tel:" + val}
        >
          {val.slice(0, 3) + "-" + val.slice(3, 6) + "-" + val.slice(6)}
        </a>
      );
    } else {
      return "unknown";
    }
  }

  function noTurnAwayBadge(val) {
    if (val) {
      return (
        <span className="align-middle inline-flex items-center rounded-md bg-purple-50 ml-5 px-2 py-1 text-lg font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
          <Link to="/noturnaway">No Turn Away</Link>
        </span>
      );
    }
  }

  const goToCheckinPage = () => {
    navigate("/checkin", { state: { properties: campsiteData } });
  };

  useEffect(() => {
    fetchCampsiteData();
  }, []);

  const LoadingPlaceholder = () => (
    <div className="animate-pulse">
      <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </div>
  );

  if (notFound) {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Campsite not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn't find the campsite you're looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Go back home
            </Link>
            <Link to="/map" className="text-sm font-semibold text-gray-900">
              View map <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Dialog
        open={showLoginPrompt}
        onClose={() => setShowLoginPrompt(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6">
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-2">
              Want to save this campsite?
            </Dialog.Title>
            <Dialog.Description className="text-sm text-gray-500 mb-4">
              Create an account or log in to save your favorite campsites and
              create collections.
            </Dialog.Description>

            <div className="mt-4 flex flex-col gap-2">
              <Link
                to="/login"
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setShowLoginPrompt(false)}
              >
                Log in
              </Link>
              <button
                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setShowLoginPrompt(false)}
              >
                Maybe later
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <CollectionsModal
        isOpen={showCollectionsModal}
        onClose={() => setShowCollectionsModal(false)}
        campsiteId={parseId(id)}
        userId={user?.id}
      />

      <div className="bg-white px-6 py-14 lg:px-8 w-full max-w-7xl mx-auto">
        <div className="mx-auto text-base leading-7 text-gray-700">
          <div className="mb-4">
            {loading ? (
              <div className="animate-pulse">
                <div className="h-8 bg-gray-200 rounded w-1/3 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              </div>
            ) : (
              <>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {campsiteData.name}
                  {noTurnAwayBadge(campsiteData.no_turn_away)}
                </h1>
                <p>{campsiteData.state}</p>
              </>
            )}
          </div>
          {/* 3 column wrapper */}
          <div className="mx-auto w-full max-w-7xl grow">
            {/* Left sidebar & main wrapper */}
            <div className="grid md:grid-cols-4 grid-cols-1">
              <div className="pr-4 col-span-1">
                {/* Left column area */}
                <div>
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Details
                  </h3>
                  {loading ? (
                    <div className="space-y-4">
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                    </div>
                  ) : (
                    <>
                      <p>
                        <span className="font-bold">Cost:</span>{" "}
                        {costFormatter(campsiteData.cost)}
                      </p>
                      <p>
                        <span className="font-bold">Type of Facility:</span>{" "}
                        {campsiteData.type_of_facility}
                      </p>
                      <p>
                        <span className="font-bold">GPS:</span>{" "}
                        <a
                          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                          target="_blank"
                          href={
                            "https://www.google.com/maps/place/" +
                            campsiteData.latitude +
                            "," +
                            campsiteData.longitude
                          }
                        >
                          {campsiteData.latitude.toString().slice(0, 7) +
                            "," +
                            campsiteData.longitude.toString().slice(0, 8)}
                        </a>
                      </p>
                      <p>
                        <span className="font-bold">Website:</span>{" "}
                        {websiteFormatter(campsiteData.website_url)}
                      </p>
                      <p>
                        <span className="font-bold">Phone:</span>{" "}
                        {phoneFormatter(campsiteData.phone_number)}
                      </p>
                    </>
                  )}
                </div>
                <div>
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Amenities
                  </h3>
                  {loading ? (
                    <div className="space-y-4">
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                    </div>
                  ) : (
                    <>
                      <p>
                        <span className="font-bold">Picnic Tables:</span>{" "}
                        {unknownFormatter(campsiteData.tables)}
                      </p>
                      <p>
                        <span className="font-bold">Picnic Shelter:</span>{" "}
                        {unknownFormatter(campsiteData.picnic_shelter)}
                      </p>
                      <p>
                        <span className="font-bold">Water:</span>{" "}
                        {unknownFormatterOptions(campsiteData.water)}
                      </p>
                      <p>
                        <span className="font-bold">Showers:</span>{" "}
                        {unknownFormatterOptions(campsiteData.showers)}
                      </p>
                      <p>
                        <span className="font-bold">Toilet:</span>{" "}
                        {unknownFormatterOptions(campsiteData.toilet)}
                      </p>
                      <p>
                        <span className="font-bold">Electricity:</span>{" "}
                        {unknownFormatterOptions(campsiteData.electricity)}
                      </p>
                      <p>
                        <span className="font-bold">Hammock:</span>{" "}
                        {unknownFormatter(campsiteData.hammocks)}
                      </p>
                      <p>
                        <span className="font-bold">Laundry:</span>{" "}
                        {unknownFormatter(campsiteData.laundry)}
                      </p>
                      <p>
                        <span className="font-bold">Wifi:</span>{" "}
                        {unknownFormatter(campsiteData.wifi)}
                      </p>
                      <p>
                        <span className="font-bold">
                          Food Storage Container:
                        </span>{" "}
                        {unknownFormatter(campsiteData.food_storage)}
                      </p>
                    </>
                  )}
                </div>
                <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                  Something wrong?
                </h3>
                <a
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  href={
                    "mailto:gobikecamping@gmail.com?subject=Report for Campsite: " +
                    campsiteData.id
                  }
                >
                  Report it
                </a>
              </div>

              <div className="md:px-4 mt-5 md:mt-0 col-span-2 md:max-w-xl">
                <div>
                  {loading ? (
                    <div className="space-y-4">
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                    </div>
                  ) : (
                    <>
                      {campsiteData.advisory ? (
                        <div className="rounded-md bg-yellow-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon
                                className="h-5 w-5 text-yellow-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-yellow-800">
                                Attention needed
                              </h3>
                              <div className="mt-2 text-sm text-yellow-700">
                                <p>{campsiteData.advisory}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                        Description
                      </h3>
                      <p>{campsiteData.description}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="md:pl-4 mt-5 md:mt-0 col-span-1">
                {/* Right column area */}
                {!loading && (
                  <>
                    <div className="text-sm text-gray-500 mb-3">
                      Added by {campsiteData.profiles?.username || "unknown"} on{" "}
                      {new Date(campsiteData.created_at).toLocaleDateString()}
                    </div>
                    <div className="flex flex-col gap-2 mb-4">
                      <button
                        onClick={toggleFavorite}
                        disabled={favoriteLoading}
                        className={`flex items-center gap-2 px-4 py-2 rounded-md font-medium ${
                          isFavorited
                            ? "bg-red-100 text-red-700 hover:bg-red-200"
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                      >
                        {isFavorited ? (
                          <HeartIconSolid className="h-5 w-5 text-red-500" />
                        ) : (
                          <HeartIcon className="h-5 w-5" />
                        )}
                        {isFavorited
                          ? "Remove from Favorites"
                          : "Add to Favorites"}
                      </button>
                      <button
                        onClick={handleAddToCollection}
                        className="flex items-center gap-2 px-4 py-2 rounded-md font-medium bg-gray-100 text-gray-700 hover:bg-gray-200"
                      >
                        <FolderPlusIcon className="h-5 w-5" />
                        Add to Collection
                      </button>
                    </div>
                  </>
                )}

                <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                  Check-ins
                </h3>
                {loading ? (
                  <div className="space-y-4">
                    {[...Array(3)].map((_, i) => (
                      <div key={i} className="border-b pb-4">
                        <div className="flex justify-between items-center mb-2">
                          <div className="w-1/3">
                            <LoadingPlaceholder />
                          </div>
                          <div className="w-24">
                            <LoadingPlaceholder />
                          </div>
                        </div>
                        <div className="w-full">
                          <LoadingPlaceholder />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <ul role="list" className="grid grid-cols-1 gap-6">
                      {campsiteData.checkins.map((checkin) => (
                        <li
                          key={checkin.id}
                          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                        >
                          <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-sm font-medium text-gray-900">
                                  {checkin.check_in_date}
                                </h3>
                                <span className="inline-flex flex-shrink-0 items-center rounded-full px-0.5 py-0.5 text-xs font-medium">
                                  by: {checkin.profiles.username}
                                </span>
                              </div>
                              <p className="mt-1 text-sm text-gray-500">
                                {checkin.description}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {campsiteData.checkins.length > 0 ? (
                      <button
                        onClick={() => goToCheckinPage()}
                        className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Check-in here
                      </button>
                    ) : (
                      <div>
                        <p>No one has checked-in yet.</p>
                        <button
                          onClick={() => goToCheckinPage()}
                          className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                          Be the first
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
