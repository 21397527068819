import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../supabase/client";

export function FooterEmailSignup() {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("idle"); // idle, loading, success, error

  useEffect(() => {
    setStatus("idle");
    setEmail("");
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");

    try {
      const { error } = await supabase.from("newsletter_subscribers").insert([
        {
          email,
          subscribed_at: new Date().toISOString(),
          status: "active",
        },
      ]);

      // If it's a duplicate email error, treat it as success
      if (error?.code === "23505") {
        setStatus("success");
        setEmail("");
        return;
      }

      if (error) throw error;

      setStatus("success");
      setEmail("");
    } catch (error) {
      console.error("Error saving email:", error);
      setStatus("error");
    }
  };

  return (
    <div className="mt-6">
      {status === "success" ? (
        <p className="text-sm text-gray-300">
          Thanks for subscribing! You'll receive updates about new features and
          campsites.
        </p>
      ) : (
        <form onSubmit={handleSubmit} className="sm:flex sm:max-w-md">
          <label htmlFor="footer-email" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            name="footer-email"
            id="footer-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            required
            placeholder="Enter your email"
            className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6"
            disabled={status === "loading"}
          />
          <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
            <button
              type="submit"
              disabled={status === "loading"}
              className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:bg-gray-600 disabled:cursor-not-allowed"
            >
              {status === "loading" ? "Subscribing..." : "Subscribe"}
            </button>
          </div>
        </form>
      )}
      {status === "error" && (
        <p className="mt-2 text-sm text-red-400">
          Something went wrong. Please try again.
        </p>
      )}
    </div>
  );
}
