import React from "react";
import { EmailSignup } from "../Components/EmailSignup";
import "../styles/App.css";
import "../index.css"; // Import your Tailwind CSS here
import MapboxComponent from "../Components/MapComponents/MapboxComponent.js";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import mapImage from "../assets/map.png";

export function LandingView({ allCampsites, isLoading }) {
  return (
    <div className="App">
      <div className="bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Where Cyclists Find Everything: Campgrounds, Bike Shops, and More.
          </h1>
          <br></br>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            The goal of this project is to map all of the points of interests a
            cycle tourist would want so that you can easily find them while
            planning, and verify them from the road.
          </p>
          <br></br>
          {!isLoading ? (
            <h3 className="text-2xl font-bold tracking-tight text-white sm:text-2xl">
              <span className="underline">{allCampsites.features.length}</span>{" "}
              campsites and counting...
            </h3>
          ) : (
            <h3
              className="text-2xl font-bold tracking-tight text-white sm:text-2xl"
              style={{ opacity: 0 }}
            >
              campsites and counting...
            </h3>
          )}
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${mapImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="mx-5 md:mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl rounded-3xl sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-3xl">
              The bike touring info you want, in one place.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              Stop scrolling forums and searching for old trip reports to get
              camping and resupply information for your tour. A lot of it is
              here.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/map"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Go to the Map <span aria-hidden="true">→</span>
              </Link>
            </div>
            <svg
              viewBox="0 0 1024 1024"
              aria-hidden="true"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            >
              <circle
                r={512}
                cx={512}
                cy={512}
                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      {/* <MapboxComponent allMapData={allCampsites}></MapboxComponent> */}
      <div className="bg-white px-6 py-8 lg:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            Phase 2 - current phase
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Mapping more than just campsites
          </h1>
          <p className="mt-6 mb-6 text-xl leading-8">
            We've added a lot of campsites to this map. There are still more to
            go. But we're also expanding to map bike shops. After that we'll
            also be adding things like places to get water and stores selling
            stove fuel.
          </p>
          <p className="text-base font-semibold leading-7 text-indigo-600">
            Phase 1
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The first sites to be added
          </h1>
          <p className="mt-6 text-xl leading-8">
            The first campsites on the map will be along the Empire State Trail
            and C&O Gap since they are popular routes and beginner friendly. I
            want to make sure we have all of the hiker/biker sites accounted
            for.
          </p>
          <div className="mt-10 max-w-2xl">
            <p>What are the criteria for a site making it onto this map?</p>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Cyclist-specific pricing.
                  </strong>{" "}
                  This is not an exhaustive map of campsites. It's a map of
                  campsites that give cyclists a cheaper rate than motorists.
                  The sites could be free, $5, even $20. As long as the price is
                  cheaper for cyclists than people who arrive by car, it can go
                  on the map.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    No reservations.
                  </strong>{" "}
                  Making reservations is hard when you're on tour. Rain, wind,
                  and mechanicals can make you miss a reservation. The sites on
                  this map can't require reservations.
                </span>
              </li>
            </ul>
          </div>
          <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Very far down the road...
            </h2>
            <p className="mt-6">
              When this site has hundreds, if not thousands of sites on it, with
              detailed lists of amenities for those sites, and cyclist reviews,
              I'm going to have to start charging. It'll help pay for the
              infrastructure costs of running the site, and also keep bad actors
              out. At that point there will be also be a mobile app that works
              offline, and hopefully you'll be getting enough value from the
              site that it'll be easy for you to pay for it.
            </p>
            <p className="mt-8">
              But you can also earn a lifetime free account by helping me get
              started. If you provide good data that ends up making it onto the
              map, I'll make sure that you always have access to the site.
              Excited by this mission? I could use some help finding and
              standardizing this data for the map. That'll involve you Googling
              around and writing things in a Google Sheet. Let me know if you
              have a few hours to share!
            </p>
          </div>
          <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              I'd love your input and feedback
            </h2>
            <p className="mt-6">
              If you're out on tour, please compare what you see with what I
              have in the app. I did my best to figure out what kind of
              amenities are available, and where in the campground the H/B site
              is. If you have a more accurate pin for the campground, and
              updates on the amentities available, please tell me.
            </p>
          </div>
          <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Help me fill in the map
            </h2>
            <p className="mt-6">
              Do you know of any hiker/biker spots that aren't on here yet? (I'm
              sure you do.) Can you send me links to maps you've made, websites
              you've seen, or even just a Google Maps link to the park? Send it
              to me via{" "}
              <Link
                to="/addNew"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                this form.
              </Link>
            </p>
            <br />
            <br />
            <EmailSignup />
          </div>
        </div>
      </div>
    </div>
  );
}
