import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import "../../styles/FilterControlStyles.css";
import MapKey from "./MapKey";

export default function FilterControl({
  freeFilterChecked,
  updateFiltersFunc,
  selectedTypes,
  updateTypeFilters,
  showBikeShops,
  toggleBikeShops,
  showCampgrounds,
  toggleShowCampgrounds,
}) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const siteTypes = [
    { id: "campground", label: "Campgrounds" },
    { id: "h/b campground", label: "Hiker/Biker Sites" },
    { id: "park", label: "Parks" },
    { id: "church", label: "Churches" },
    { id: "rv park", label: "RV Parks" },
    // { id: "no-turn-away", label: "No Turn Away" },
    { id: "dispersed", label: "Dispersed Camping" },
    { id: "business", label: "Businesses" },
    { id: "hostel", label: "Hostels" },
    { id: "hotel", label: "Hotels" },
  ];

  const renderFilterSection = (isMobile = false) => (
    <div className="space-y-4">
      <div className="space-y-3">
        <div key="free" className="flex items-center">
          <input
            id={isMobile ? "free-mobile" : "free"}
            name="free"
            checked={freeFilterChecked}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            onChange={updateFiltersFunc}
          />
          <label
            htmlFor={isMobile ? "free-mobile" : "free"}
            className="ml-3 text-sm text-gray-600"
          >
            Free Sites Only
          </label>
        </div>
        <div className="space-y-3">
          <div key="bikeshops" className="flex items-center">
            <input
              id={isMobile ? "bikeshops-mobile" : "bikeshops"}
              name="bikeshops"
              checked={showBikeShops}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={toggleBikeShops}
            />
            <label
              htmlFor={isMobile ? "bikeshops-mobile" : "bikeshops"}
              className="ml-3 text-sm text-gray-600"
            >
              Show Bike Shops
            </label>
          </div>
          <div key="showcampgrounds" className="flex items-center">
            <input
              id={isMobile ? "showcampgrounds-mobile" : "showcampgrounds"}
              name="showcampgrounds"
              checked={showCampgrounds}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={toggleShowCampgrounds}
            />
            <label
              htmlFor={isMobile ? "showcampgrounds-mobile" : "showcampgrounds"}
              className="ml-3 text-sm text-gray-600"
            >
              Show Campgrounds
            </label>
          </div>
        </div>
      </div>

      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <DisclosureButton className="flex w-full justify-between text-left text-sm font-medium text-gray-900">
              <span>Site Types</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-gray-500`}
              />
            </DisclosureButton>
            <DisclosurePanel className="pt-2 pb-4">
              <div className="space-y-2">
                {siteTypes.map((type) => (
                  <div key={type.id} className="flex items-center">
                    <input
                      id={`${type.id}${isMobile ? "-mobile" : ""}`}
                      name={type.id}
                      type="checkbox"
                      checked={selectedTypes.includes(type.id)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={() => updateTypeFilters(type.id)}
                    />
                    <label
                      htmlFor={`${type.id}${isMobile ? "-mobile" : ""}`}
                      className="ml-3 text-sm text-gray-600"
                    >
                      {type.label}
                    </label>
                  </div>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );

  return (
    <div id="filterControlStyles" className="bg-white m-2">
      <div>
        {/* Mobile filter dialog */}
        <Dialog
          className="relative z-40 lg:hidden"
          open={mobileFiltersOpen}
          onClose={setMobileFiltersOpen}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                <fieldset>
                  <div className="px-4 pb-2 pt-4">
                    {renderFilterSection(true)}
                  </div>
                </fieldset>
                <div className="p-4">
                  <MapKey />
                </div>
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto px-4 py-4">
          <div className="lg:grid">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="text-sm font-medium text-gray-700">
                  Filters/Key
                </span>
                <PlusIcon
                  className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              </button>

              <div className="hidden lg:block">
                <form className="space-y-10 divide-y divide-gray-200">
                  <fieldset>{renderFilterSection()}</fieldset>
                </form>
              </div>
              <div className="hidden lg:block py-4">
                <MapKey />
              </div>
            </aside>
          </div>
        </main>
      </div>
    </div>
  );
}
